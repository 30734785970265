import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
    title: "Изменить имя",
    permanent: "",
    description: "Введенное вами имя будет видно другим пользователям."
  }, {
    body: _withCtx(() => [
      _withDirectives(_createVNode(_component_Field, {
        modelValue: _ctx.newName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event)),
        outlined: ""
      }, null, 8, ["modelValue"]), [
        [_directive_focus]
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_Btn, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = false)),
        label: "Отмена"
      }),
      _createVNode(_component_Btn, {
        filled: "",
        label: "Продолжить",
        onClick: _ctx.update,
        loading: _ctx.loading,
        disabled: !_ctx.newName.length
      }, null, 8, ["onClick", "loading", "disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}