
import { defineComponent } from "vue";
import Item from "@/components/profile/item.vue";
import TopAppBar from "@/components/ui/top-app-bar";
import UpdateName from "@/components/profile/update-name.vue";
import UpdateAvatar from "@/components/profile/update-avatar.vue";
import { Card, SignIn, SignUp, ResetPassword } from "@/components/login";

export default defineComponent({
  name: "Profile View",

  components: {
    Item,
    Card,
    TopAppBar,
    UpdateName,
    UpdateAvatar,
    SignIn,
    SignUp,
    ResetPassword,
  },

  data: () => ({
    editName: false,
    editAvatar: false,
  }),

  computed: {
    me() {
      return this.$fb.users.state;
    },
    version() {
      return process.env.VERSION;
    },
    signIn: {
      get() {
        return "signIn" in this.$route.query;
      },

      set(v: boolean) {
        if (v) this.$router.push({ query: { signIn: null } });
        else if (window?.history.state?.back == "/profile") this.$router.back();
        else this.$router.replace("/profile");
      },
    },
    signUp: {
      get() {
        return "signUp" in this.$route.query;
      },

      set(v: boolean) {
        if (v) this.$router.push({ query: { signUp: null } });
        else if (window?.history.state?.back == "/profile") this.$router.back();
        else this.$router.replace("/profile");
      },
    },
    resetPassword: {
      get() {
        return "resetPassword" in this.$route.query;
      },

      set(v: boolean) {
        if (v) this.$router.push({ query: { resetPassword: null } });
        else if (window?.history.state?.back == "/profile") this.$router.back();
        else this.$router.replace("/profile");
      },
    },

    component() {
      return this.$device.isDesktop ? "Dialog" : "Popup";
    },
    canLinkGoogle() {
      return (
        !this.$fb.auth.isAnonymous &&
        !this.$fb.auth.providers.includes("google.com")
      );
    },
  },
});
