
import { defineComponent } from "vue";

export default defineComponent({
  name: "Update Name",

  props: {
    modelValue: { type: Boolean, default: false },
  },

  emits: ["update:modelValue"],

  data: () => ({
    loading: false,
    newAvatar: 0,
  }),

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(v: boolean) {
        this.$emit("update:modelValue", v);
      },
    },
    component() {
      return this.$device.isDesktop ? "Dialog" : "Popup";
    },
  },

  watch: {
    value(v) {
      if (v) this.newAvatar = this.$fb.users.state.avatar;
    },
  },

  methods: {
    update() {
      this.loading = true;
      this.$fb.users
        .setAvatar(this.newAvatar)
        .then(() => (this.value = false))
        .catch((e) => console.error(e))
        .finally(() => (this.loading = false));
    },
  },
});
