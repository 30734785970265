
import { defineComponent } from "vue";
import Btn from "@/components/ui/btn.vue";

export default defineComponent({
  name: "Google Button",

  components: { Btn },

  data: () => ({
    loading: false,
  }),

  methods: {
    onclick() {
      this.loading = true;
      this.$fb.auth.signInWithGoogle().finally(() => (this.loading = false));
    },
  },
});
