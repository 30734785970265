
import { defineComponent } from "vue";
import Google from "./google.vue";

export default defineComponent({
  components: { Google },

  data: () => ({
    email: "",
    password: "",
    loading: false,
  }),

  computed: {
    component() {
      return this.$device.isDesktop ? "Dialog" : "Popup";
    },
  },

  methods: {
    onclick() {
      if (!this.email || !this.password) return;
      this.loading = true;
      this.$fb.auth
        .signUp(this.email, this.password)
        .then(() => {
          if (window?.history.state?.back == "/profile") this.$router.back();
          else this.$router.replace("/profile");
        })
        .finally(() => (this.loading = false));
    },
  },
});
