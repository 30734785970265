
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    email: "",
    loading: false,
  }),

  computed: {
    component() {
      return this.$device.isDesktop ? "Dialog" : "Popup";
    },
  },

  methods: {
    onclick() {
      if (!this.email) return;
      this.loading = true;

      this.$fb.auth
        .resetPassword(this.email)
        .then(() => {
          if (window?.history.state?.back == "/profile") this.$router.back();
          else this.$router.replace("/profile");

          this.$device.toast("Письмо успешно отправлено вам на почту.")
        })
        .finally(() => (this.loading = false));
    },
  },
});
