import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onclick && _ctx.onclick(...args))),
    class: "profile-item",
    tabindex: "0"
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.iconClass),
          textContent: _toDisplayString(_ctx.icon)
        }, null, 10, _hoisted_1))
      : _renderSlot(_ctx.$slots, "default", { key: 1 }),
    _createElementVNode("span", {
      textContent: _toDisplayString(_ctx.label)
    }, null, 8, _hoisted_2)
  ]))
}