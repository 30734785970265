import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
    title: "Восстановить пароль",
    description: "Если учетная запись существует, вы получите письмо с инструкциями по восстановлению пароля. Если письма нет, проверьте папку \"Спам\"."
  }, {
    body: _withCtx(() => [
      _createElementVNode("form", null, [
        _createVNode(_component_Field, {
          type: "email",
          label: "Email",
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          autocomplete: "email"
        }, null, 8, ["modelValue"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_Btn, {
        to: { query: { signIn: null } },
        label: "Войти в аккаунт",
        style: {"margin-right":"auto"},
        replace: ""
      }),
      _createVNode(_component_Btn, {
        label: "Восстановить",
        filled: "",
        onClick: _ctx.onclick,
        loading: _ctx.loading
      }, null, 8, ["onClick", "loading"])
    ]),
    _: 1
  }))
}